import React from "react";

const Intervenant = (props) => {
    const {company, companyColor, hide, name, nameColor} = props;
    return (
        <>
            {!hide && name && (
                <div className="col">
                    {nameColor && (
                        <p style={{color: nameColor}} className="name">
                            {name}
                        </p>
                    )}
                    {!nameColor && <p className="name">{name}</p>}
                    {nameColor && (
                        <span style={{color: companyColor}} className="company">
							{company}
						</span>
                    )}
                    {!nameColor && <span className="company">{company}</span>}
                </div>
            )}
        </>
    );
};

export default Intervenant;