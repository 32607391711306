import React from "react"

import {Button, Card, Input} from "semantic-ui-react"

import ModalDelete from "./ModalDelete"

const MyCard = props => {
    const {data, sortIndex, handleFormName, handleFormCompany} = props
    const valueName = data.name
    const valueCompany = data.company

    return (
        <Card style={{cursor: "pointer"}}>
            <Card.Content>
                <Card.Header>
                    Intervenant {sortIndex + 1} : {valueName}
                </Card.Header>
                <Input
                    fluid
                    type="text"
                    placeholder={valueName}
                    value={valueName}
                    onChange={e => handleFormName(e.target.value)}
                />
                <br/>
                <Card.Header>Entreprise : {valueCompany}</Card.Header>
                <Input
                    fluid
                    type="text"
                    placeholder={valueCompany}
                    value={valueCompany}
                    onChange={e => handleFormCompany(e.target.value)}
                />
            </Card.Content>
            <Card.Content extra>
                <div className="ui two buttons">
                    <Button color="blue" basic onClick={() => props.showLive(sortIndex)}>
                        {data.hide ? "Afficher Live" : "Masquer Live"}
                    </Button>
                    <ModalDelete
                        deleteIntervenants={props.deleteIntervenants}
                        index={sortIndex}
                    />
                </div>
            </Card.Content>
        </Card>
    )
}

export default MyCard

