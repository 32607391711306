import React from 'react';

import './nav.css';
import {Link} from 'react-router-dom';
import {Button} from 'semantic-ui-react';

const Nav = () => {
    return (
        <div className="nav">
            <Link to={'/'}>
                <Button color="blue">Vue d'ensemble</Button>
            </Link>
            <Link to={'/setup/Hall'}>
                <Button>Hall</Button>
            </Link>
            <Link to={'/setup/101'}>
                <Button>101</Button>
            </Link>
            <Link to={'/setup/cd31'}>
                <Button>CD31</Button>
            </Link>
            <Link to={'/setup/108'}>
                <Button>108</Button>
            </Link>
            <Link to={'/setup/115'}>
                <Button>115</Button>
            </Link>
            <Link to={'/setup/116'}>
                <Button>116</Button>
            </Link>
        </div>
    );
};
export default Nav;
