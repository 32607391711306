import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Grid, Container, Header, Button, Card} from 'semantic-ui-react';
import {InputColor} from './components/InputColor/InputColor';

class Coucou extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Grid stackable style={{textAlign: 'center'}}>
                <Grid.Column>
                    <Container>
                        <Header as="h1" style={{margin: '3em auto'}}>
                            Salut à toi, jeune régisseur!
                        </Header>
                        <Header as="h2" style={{margin: '3em auto'}}>
                            Choisis ta salle, et la question sera vite répondue!
                        </Header>
                        <div className="colors"><InputColor/></div>
                        <Card.Group centered>

                            <Card>
                                <Card.Content>
                                    <Card.Header style={{fontSize: '3rem', color: 'green'}}>Hall</Card.Header>
                                    <Card.Meta>Rez de Chaussée</Card.Meta>
                                </Card.Content>
                                <Card.Content extra>
                                    <Link to="/setup/hall">
                                        <Button size="huge" basic color="green">
                                            Entrer
                                        </Button>
                                    </Link>
                                </Card.Content>
                            </Card>
                            <Card>
                                <Card.Content>
                                    <Card.Header style={{fontSize: '3rem', color: 'gold'}}>Salle 101</Card.Header>
                                    <Card.Meta>1er étage coté QDS</Card.Meta>
                                </Card.Content>
                                <Card.Content extra>
                                    <Link to="/setup/101">
                                        <Button size="huge" basic color="yellow">
                                            Entrer
                                        </Button>
                                    </Link>
                                </Card.Content>
                            </Card>
                            <Card>
                                <Card.Content>
                                    <Card.Header style={{fontSize: '3rem', color: 'red'}}>CD31</Card.Header>
                                    <Card.Meta>Conseil départemental</Card.Meta>
                                </Card.Content>
                                <Card.Content extra>
                                    <Link to="/setup/cd31">
                                        <Button size="huge" basic color="red">
                                            Entrer
                                        </Button>
                                    </Link>
                                </Card.Content>
                            </Card>
                            <Card>
                                <Card.Content>
                                    <Card.Header style={{fontSize: '3rem', color: '#FF00FF'}}>Salle 108</Card.Header>
                                    <Card.Meta>1er étage coté Cantine</Card.Meta>
                                </Card.Content>
                                <Card.Content extra>
                                    <Link to="/setup/108">
                                        <Button size="huge" basic color="pink">
                                            Entrer
                                        </Button>
                                    </Link>
                                </Card.Content>
                            </Card>
                            <Card>
                                <Card.Content>
                                    <Card.Header style={{fontSize: '3rem', color: 'purple'}}>Salle 115</Card.Header>
                                    <Card.Meta>1er étage coté Cantine</Card.Meta>
                                </Card.Content>
                                <Card.Content extra>
                                    <Link to="/setup/115">
                                        <Button size="huge" basic color="purple">
                                            Entrer
                                        </Button>
                                    </Link>
                                </Card.Content>
                            </Card>
                            <Card>
                                <Card.Content>
                                    <Card.Header style={{fontSize: '3rem', color: 'blue'}}>Salle 116</Card.Header>
                                    <Card.Meta>1er étage coté Cantine</Card.Meta>
                                </Card.Content>
                                <Card.Content extra>
                                    <Link to="/setup/116">
                                        <Button size="huge" basic color="blue">
                                            Entrer
                                        </Button>
                                    </Link>
                                </Card.Content>
                            </Card>
                        </Card.Group>
                    </Container>
                </Grid.Column>
            </Grid>
        );
    }
}

export default Coucou;
