import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {db} from './firebase';

import {ColorContext} from './context/ColorContext';

import ControlPanelHall from "./components/salles/salle_hall/ControlPanelHall";
import ControlPanel101 from "./components/salles/salle_101/ControlPanel101";
import ControlPanel107 from "./components/salles/salle_107/ControlPanel107";
import ControlPanel108 from "./components/salles/salle_108/ControlPanel108";
import ControlPanel115 from "./components/salles/salle_115/ControlPanel115";
import ControlPanel116 from "./components/salles/salle_116/ControlPanel116";

import FinalViewHall from './components/salles/salle_hall/FinalViewHall';
import FinalView101 from "./components/salles/salle_101/FinalView101";
import FinalView107 from "./components/salles/salle_107/FinalView107";
import FinalView108 from "./components/salles/salle_108/FinalView108";
import FinalView115 from "./components/salles/salle_115/FinalView115";
import FinalView116 from "./components/salles/salle_116/FinalView116";

import Coucou from './Coucou';

import './App.css';

const App = () => {
    const [colorSettings, setColorSettings] = useState({});
    useEffect(() => {
        try {
            db.ref('configIntervenant/colorSettings').on('value', (snapshot) => {
                return setColorSettings(snapshot.val());
            });
        } catch (error) {
            console.error(error);
        }
    }, []);

    return (
        <Router>
            <ColorContext.Provider value={colorSettings}>
                <Route exact path="/" component={Coucou}/>

                <Route exact path="/hall" component={FinalViewHall}/>
                <Route exact path="/101" component={FinalView101}/>
                <Route exact path="/cd31" component={FinalView107}/>
                <Route exact path="/108" component={FinalView108}/>
                <Route exact path="/115" component={FinalView115}/>
                <Route exact path="/116" component={FinalView116}/>

                <Route exact path="/setup/hall" component={ControlPanelHall}/>
                <Route exact path="/setup/101" component={ControlPanel101}/>
                <Route exact path="/setup/cd31" component={ControlPanel107}/>
                <Route exact path="/setup/108" component={ControlPanel108}/>
                <Route exact path="/setup/115" component={ControlPanel115}/>
                <Route exact path="/setup/116" component={ControlPanel116}/>
            </ColorContext.Provider>
        </Router>
    );
};

export default App;
