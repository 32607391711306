import React, {useState} from "react";
import {Button, Header, Icon, Modal} from "semantic-ui-react";

const ModalDelete = (props) => {
    const {index, deleteIntervenants} = props;
    const [open, setOpen] = useState(false);

    return (
        <Modal
            closeIcon
            open={open}
            trigger={<Button basic color='red'> Supprimer </Button>}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        >
            <Header content='Attention !'/>
            <Modal.Content>
                <p> Êtes-vous sûr de vouloir supprimer ? </p>
            </Modal.Content>
            <Modal.Actions>
                <Button color='red' onClick={() => setOpen(false)}>
                    <Icon name='remove'/> Annuler
                </Button>
                <Button color='green' onClick={() => deleteIntervenants(index)}>
                    <Icon name='checkmark'/> Supprimer
                </Button>
            </Modal.Actions>
        </Modal>
    )
};

export default ModalDelete;