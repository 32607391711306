import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import './InputColor.css';
import { Button } from 'semantic-ui-react';

export const InputColor = (props) => {
	const [colorSettings, setColorSettings] = useState({
		companyColor: '#A1A1A1',
		nameColor: '#000000',
	});
	const [resetColor, setResetColor] = useState(0);

	const handleChange = (e) => {
		setColorSettings({ ...colorSettings, [e.target.name]: e.target.value });
	};

	const updateIntervenantColorSettings = async (e) => {
		e.preventDefault();

		try {
			await db.ref('configIntervenant/colorSettings').update({
				companyColor: colorSettings.companyColor,
				nameColor: colorSettings.nameColor,
			});
			console.log('Nouvelles couleurs enregistrées en db');
			alert('Couleurs des intervenants configurées :)');
			setResetColor(resetColor + 1);
		} catch (error) {
			console.error('error from Update "Intervenant" Color Settings', error);
		}
	};

	const resetDefaultColor = (e) => {
		e.preventDefault();
		setColorSettings({ ...colorSettings, companyColor: '#A1A1A1', nameColor: '#000000' });
	};

	useEffect(() => {
		db.ref('configIntervenant/colorSettings').on('value', (snapshot) => {
			setColorSettings(snapshot.val());
			// console.log('couleurs en db :', snapshot.val());
		});
		setResetColor(0);
	}, [resetColor]);

	return (
		<div className="inputColor">
			<h3>Choisis la couleur des intervenants ici : </h3>
			<div className="inputBox">
				<div className="inputItem">
					<p>Nom</p>
					<input
						onChange={(e) => {
							handleChange(e);
						}}
						type="color"
						defaultValue={colorSettings.nameColor}
						name="nameColor"
					/>
					<p>{colorSettings.nameColor}</p>
				</div>
				{/* <div className="inputItem">
					<p>Company</p>
					<input
						name="companyColor"
						onChange={(e) => {
							handleChange(e);
						}}
						defaultValue={colorSettings.companyColor}
						type="color"
					/>
					<p>{colorSettings.companyColor}</p>
				</div> */}
			</div>
			<Button size="medium" color="blue" onClick={(e) => updateIntervenantColorSettings(e)}>
				Valider
			</Button>
			<br /><br />
			<Button size="mini" color="grey" onClick={(e) => resetDefaultColor(e)}>
				Réinitialiser les couleurs par défault
			</Button>
		</div>
	);
};
