import React, {useEffect, useState} from 'react';
import {db} from '../../../firebase';
import LiveBoxIntervenants from "../../../utils/LiveboxIntervenants";

import '../../../App.css';

import {ReactComponent as Bandeau1} from '../../../images/bandeau1.svg';
import {ReactComponent as Bandeau2} from '../../../images/bandeau2.svg';

const FinalViewHall = () => {
    const [open, setOpen] = useState(false);
    const [intervenants, setIntervenants] = useState([]);
    const [showBandeau1, setShowBandeau1] = useState(false);
    const [showBandeau2, setShowBandeau2] = useState(false);

    const fetchData = () => {
        db.ref("salles/hall").on("value", snapshot => {
            const data = snapshot.val();
            setOpen(data.open);
            setIntervenants(data.intervenants);
        });
    };

    useEffect(() => {
        fetchData();
        return () => {
            db.ref("salles/hall").off("value", fetchData);
        };
    }, []);

    useEffect(() => {
        let toggle = 0; // A counter to toggle between none, Bandeau1, and Bandeau2

        const interval = setInterval(() => {
            if (toggle === 0) {
                setShowBandeau1(true);
                setShowBandeau2(false);
            } else if (toggle === 1 || toggle === 3) {
                setShowBandeau1(false);
                setShowBandeau2(false);
            } else if (toggle === 2) {
                setShowBandeau1(false);
                setShowBandeau2(true);
            }

            toggle = (toggle + 1) % 4; // Loop the toggle counter
        }, (30000)); // 30 seconds

        // Cleanup
        return () => {
            clearInterval(interval);
        }
    }, []);

    return (
        <>
            <div className="bandeau-streaming">
                {showBandeau1 && <Bandeau1/>}
                {showBandeau2 && <Bandeau2/>}
            </div>
            <div className="streaming-view">
                <LiveBoxIntervenants open={open} intervenants={intervenants}/>
            </div>
        </>
    );
};

export default FinalViewHall;



 