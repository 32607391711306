import React, {useContext} from "react"
import {ColorContext} from "../context/ColorContext"
import Intervenant from "./Intervenant"

const LiveboxIntervenants = props => {
    const {intervenants, open} = props
    const colorIntervenant = useContext(ColorContext)

    return (
        <>
            {colorIntervenant && (
                <div
                    style={{
                        borderColor: colorIntervenant.nameColor,
                    }}
                    className={` live-box ${open ? "show" : ""} `}
                >
                    <div className="row">
                        {intervenants.map((item, index) => (
                            <Intervenant
                                key={index}
                                index={index}
                                name={item.name}
                                company={item.company}
                                hide={item.hide}
                                nameColor={colorIntervenant.nameColor}
                                companyColor={colorIntervenant.companyColor}
                            />
                        ))}
                    </div>
                </div>
            )}

            {!colorIntervenant && (
                <div className={`live-box ${open ? "show" : ""} `}>
                    <div className="row">
                        {intervenants.map((item, index) => (
                            <Intervenant
                                key={index}
                                index={index}
                                name={item.name}
                                company={item.company}
                                hide={item.hide}
                            />
                        ))}
                    </div>
                </div>
            )}
        </>
    )
}

export default LiveboxIntervenants
