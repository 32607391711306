import firebase from 'firebase';

const config = {
	apiKey: 'AIzaSyAKnmClusQg4EMcdGz12I7QJww8MUwIzOs',
	authDomain: 'editeur-d-habillage-live.firebaseapp.com',
	databaseURL: 'https://editeur-d-habillage-live.firebaseio.com',
	projectId: 'editeur-d-habillage-live',
	storageBucket: 'editeur-d-habillage-live.appspot.com',
	messagingSenderId: '999195209442',
	appId: '1:999195209442:web:b3e0ea30058ecd1c6df8bb',
};

firebase.initializeApp(config);

export const db = firebase.database();
