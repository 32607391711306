import React, {useEffect, useState} from "react"

import {arrayMoveImmutable} from "array-move"
import {SortableContainer, SortableElement} from "react-sortable-hoc"
import {
    Button,
    Card,
    Container,
    Header,
    Input,
    Tab,
} from "semantic-ui-react"
import {db} from "../../../firebase"

import {v4 as uuidv4} from "uuid"
import Nav from "../../Nav/Nav"
import LiveboxIntervenants from "../../../utils/LiveboxIntervenants"
import MyCard from "../../../utils/MyCard";

const SortableItem = SortableElement(
    ({data, index, sortIndex, showLive, deleteIntervenants, handleFormName, handleFormCompany}) => (
        <MyCard
            data={data}
            index={index}
            sortIndex={sortIndex}
            showLive={showLive}
            handleFormName={handleFormName}
            handleFormCompany={handleFormCompany}
            deleteIntervenants={deleteIntervenants}
        />
    )
)

const PanelIntervenants = SortableContainer(
    ({intervenants, setIntervenants, ...props}) => (
        <Card.Group centered>
            {intervenants.map((data, index) => {
                return (
                    <SortableItem
                        {...props}
                        key={`item-${data.id}`}
                        index={index}
                        sortIndex={index}
                        data={data}
                        handleFormName={value =>
                            setIntervenants(
                                intervenants.map((intervenant, i) =>
                                    i === index ? {...intervenant, name: value} : intervenant
                                )
                            )
                        }
                        handleFormCompany={value =>
                            setIntervenants(
                                intervenants.map((intervenant, i) =>
                                    i === index ? {...intervenant, company: value} : intervenant
                                )
                            )
                        }
                    />
                )
            })}
        </Card.Group>
    )
)

const ControlPanel101 = () => {
    const [intervenants, setIntervenants] = useState([])
    const [name, setName] = useState("")
    const [company, setCompany] = useState("")

    useEffect(() => {
        db.ref('salles/salle101').on('value',
            async (snapshot) => setIntervenants(snapshot.val().intervenants));
    }, [])

    const onSortEnd = ({oldIndex, newIndex}) => {
        setIntervenants(arrayMoveImmutable(intervenants, oldIndex, newIndex))
    }

    const showLive = i => {
        const data = [...intervenants]
        data[i].hide = !intervenants[i].hide
        setIntervenants(data)
    }

    const deleteIntervenants = i => {
        setIntervenants([
            ...intervenants.slice(0, i),
            ...intervenants.slice(i + 1),
        ])
    }

    const addName = e => setName(e.target.value)

    const addCompany = e => setCompany(e.target.value)

    const addIntervenant = async e => {
        e.preventDefault()
        if (name === "" && company === "") {
            alert("Veuillez rentrer les informations !")
            return
        }
        await db.ref("salles/salle101/intervenants")
            .set([...intervenants, {company, name, hide: true, id: uuidv4()}])
    }

    const handleSubmit = async e => {
        e.preventDefault()
        await db.ref("salles/salle101/intervenants").set(intervenants)
    }

    const pane = [
        {
            menuItem: "Intervenants",
            render: () => (
                <Tab.Pane>
                    <PanelIntervenants
                        onSortEnd={onSortEnd}
                        intervenants={intervenants}
                        showLive={showLive}
                        setIntervenants={setIntervenants}
                        deleteIntervenants={deleteIntervenants}
                        axis="xy"
                        shouldUseDragHandle={true}
                    />
                </Tab.Pane>
            ),
        }
    ]
    
        if (intervenants.length === 0) {
        setIntervenants([{name: 'Test', company: 'Tesla', hide: false}])
    }

    return (
        <>
            <Nav/>
            <br/>
            <Header as="h1" textAlign="center">
                Panneau de contrôle Salle 101
            </Header>
            <Container textAlign="center">
                <h3> Actuellement {intervenants.length} intervenant(s) </h3>
                <h3> Ajouter un intervenant : </h3>
                <form onSubmit={addIntervenant}>
                    <Input onChange={addName} placeholder="Prénom Nom"/>
                    <Input onChange={addCompany} placeholder="Entreprise"/>
                    <Button type="submit" color="green">
                        Ajouter l'intervenant
                    </Button>
                </form>
                <h3> Vue Intervenants </h3>
                <LiveboxIntervenants open={true} intervenants={intervenants}/>
                <h3>
                    Pour enregistrer les modifications
                    (ordre, afficher/masquer ou supprimer un intervenant),
                    cliquer sur le bouton ci-dessous ! ️
                </h3>
                <Button
                    size="huge"
                    color="blue"
                    onClick={handleSubmit}
                    type="submit"
                >
                    Enregistrer les modifications
                </Button>
            </Container>
            <br/>
            <Tab panes={pane}/>
            <br/>
            <Container textAlign="center">
                <h3> Bonne Mêlée Numérique 2022 à vous tous !
                    <span role="img" aria-labelledby="love">❤️</span></h3>
            </Container>
            <br/>
        </>
    )
}

export default ControlPanel101
